<template>
  <h1>{{ pageTitle }} </h1>
</template>

<script>
export default {
  data() {
    return {
      pageTitle: 'People',
    }
  },
}
</script>

<style>
h1{
  margin: auto;
}
</style>
